import React, { useEffect, useState } from "react";
import { SearchOutlined, FileAddOutlined } from "@ant-design/icons";
import {
  Table,
  Input,
  Button,
  Popconfirm,
  Space,
  Tag,
  Modal,
  Select,
  Checkbox,
} from "antd";
import { getAll, getDersDagilim } from "../../infrastructure/ws";

const DersDagilim = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [sortedInfo, setSortedInfo] = useState({});
  const [fields, setFields] = useState({});
  const [open, setOpen] = useState(0);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [record, setRecord] = useState({});
  const [allDeps, setAllDeps] = useState(false);
  const [selectedDep, setSelectedDep] = useState("");
  const [deps, setDeps] = useState([]);

  const [searchText, setSearchText] = useState("");

  let [filteredData] = useState();

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    setLoading(true);

    getAll("ders/dersBirimAdi").then((depResult) => {
      console.log(depResult);
      depResult.sort();
      const depsDataSoure = depResult.map((dep) => {
        return {
          value: dep,
          label: dep,
        };
      });

      setDeps(depsDataSoure);
      getDersDagilim(searchText).then((result) => {
        setLoading(false);

        if (allDeps) {
          filteredData = result.filter((value) => {
            return value.adSoyad
              .toLowerCase()
              .includes(searchText.toLowerCase());
          });
        } else {
          filteredData = result.filter((value) => {
            return (
              value.egitmenProgramAdi.toLowerCase() == selectedDep.toLowerCase()
            );
          });
        }

        console.log(searchText);

        setData(filteredData);
      });
    });
  };

  const handleInputChange = (e) => {
    setSearchText(e.target.value);
  };

  const handleChange = (...sorter) => {
    const { order, field } = sorter[2];
    setSortedInfo({ columnKey: field, order });
  };

  const checkChanged = (e) => {
    setAllDeps(e.target.checked);
  };

  const onSelectedDepChanged = (e) => {
    setSelectedDep(e);
  };

  const columns = [
    {
      title: "Eğitmen Program Adı",
      dataIndex: "egitmenProgramAdi",
      align: "left",
      sorter: (a, b) => a.egitmenProgramAdi.localeCompare(b.egitmenProgramAdi),
      sortOrder:
        sortedInfo.columnKey === "egitmenProgramAdi" && sortedInfo.order,
    },
    {
      title: "Ünvan",
      dataIndex: "kisaUnvan",
      align: "left",
      sorter: (a, b) => a.kisaUnvan.localeCompare(b.kisaUnvan),
      sortOrder: sortedInfo.columnKey === "kisaUnvan" && sortedInfo.order,
    },
    {
      title: "Ad Soyad",
      dataIndex: "adSoyad",
      align: "left",
      sorter: (a, b) => a.adSoyad.localeCompare(b.adSoyad),
      sortOrder: sortedInfo.columnKey === "adSoyad" && sortedInfo.order,
    },
    {
      title: "Birim İçi",
      dataIndex: "birimİci",
      align: "right",
      sorter: (a, b) => a.birimİci > b.birimİci,
      sortOrder: sortedInfo.columnKey === "birimİci" && sortedInfo.order,
    },
    {
      title: "Birim Dışı",
      dataIndex: "birimDisi",
      align: "right",
      sorter: (a, b) => a.birimDisi > b.birimDisi,
      sortOrder: sortedInfo.columnKey === "birimDisi" && sortedInfo.order,
    },
    {
      title: "Toplam",
      dataIndex: "toplam",
      align: "right",
      sorter: (a, b) => a.toplam > b.toplam,
      sortOrder: sortedInfo.columnKey === "toplam" && sortedInfo.order,
    },
  ];

  const globalSearch = async (e) => {
    await loadData();
    console.log(searchText);
    filteredData = await data.filter((value) => {
      return value.egitmenProgramAdi
        .toLowerCase()
        .includes(searchText.toLowerCase());
    });

    console.log(searchText);

    setData(filteredData);
  };

  return (
    <div>
      <Space>
        <Space>
          <Checkbox checked={allDeps} onChange={checkChanged}>
            Tüm Şubeler
          </Checkbox>
          {!allDeps && (
            <Select
              options={deps}
              onChange={onSelectedDepChanged}
              style={{
                width: 380,
              }}
            />
          )}
        </Space>
        <Input
          style={{ width: 200, margin: 10 }}
          placeholder="Filtre"
          type="text"
          allowClear
          value={searchText}
          onChange={handleInputChange}
        />
        <Button onClick={globalSearch} type="primary" icon={<SearchOutlined />}>
          Listele
        </Button>
        <Tag color="orange">({data.length})</Tag>
      </Space>

      <br />
      <br />
      <Table
        columns={columns}
        dataSource={data}
        pagination={{ pageSize: 100 }}
        bordered
        loading={loading}
        onChange={handleChange}
        size="middle"
        rowKey="id"
      />
    </div>
  );
};

export default DersDagilim;
